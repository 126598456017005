import axios from 'axios';
import toastr from 'toastr';

import {
  hideCancelSubscriptionModal,
  showCancelSubscriptionModal
} from './modals';
import {
  getRecaptchaSiteKey,
  hideError,
  isFormValid,
  showError,
  toggleButtonLoading,
  threeDSecureConfig,
  braintreeDropinConfig,
} from '../utils';
import {
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  RECAPTCHA_ACTIONS
} from '../config';
import {handleDropinToken} from './payment/subscription.utils';


$(document).ready(() => {
  const $accountSettings = $('[data-selector="account-settings"]');
  if (!$accountSettings.length) return;

  const tabBtn = '[data-selector="tab-btn"]';
  const tabContent = '[data-selector="tab-content"]';
  const itemIdAttr = 'data-id';
  const activeClassName = '_active';

  // cancel subscription flow
  const cancelSubscriptionBtn = '[data-selector="cancel-subscription"]';
  const cancelConfirmBtn = '[data-selector="cancel-confirm"]';
  const cancelDeclineBtn = '[data-selector="cancel-decline"]';
  const $cancelFlowStepOneBlock = $('[data-selector="step-one"]');
  const $cancelFlowStepTwoBlock = $('[data-selector="step-two"]');
  const $cancelSubscriptionForm = $('[data-selector="reason-form"]');
  const $textArea = $('[data-selector="cancel-note"]');
  const $cancelMessage = $('[data-selector="cancel-message"]');
  const updatePaymentMethod = '[data-selector="update-payment-method"]';
  const $updatePaymentMethod = $(updatePaymentMethod);
  const submitReason = '[data-selector="submit-reason"]';
  const selectedReason = 'input[name="reason"]:checked';
  const $submitReason = $(submitReason);
  const dropinLoader = '[data-selector="dropin-loader]';
  const subscriptionPriceAttr = 'data-subscription-price';
  const PAYMENT_METHOD_TAB_ID = 'payment';

  const siteKey = getRecaptchaSiteKey();
  if (!siteKey) {
    showError(null, $updatePaymentMethod);
    throw new Error('No reCaptcha site key added to HTML');
  }


  const braintreeDropinInit = function () {
    const $dropinContainer = $('#dropin-container');
    if ($dropinContainer.html() !== '') $dropinContainer.html('');
    const braintreeConf = braintreeDropinConfig();

    braintree.dropin.create(braintreeConf, (createErr, instance) => {
      if (createErr) {
        toastr.warning('Something went wrong!','',{timeOut: 5000});
        return;
      }

      // This will be true if you generated the client token with a customer ID and there is
      // a saved payment method available to tokenize with that customer.
      if (instance.isPaymentMethodRequestable()) $updatePaymentMethod.show();

      $(dropinLoader).hide();

      instance.on('paymentMethodRequestable', () => {
        $updatePaymentMethod.show();
        hideError();
      });

      instance.on('noPaymentMethodRequestable', () => {
        $updatePaymentMethod.hide();
      });

      $updatePaymentMethod.click(function () {
        hideError();
        toggleButtonLoading($updatePaymentMethod, true);

        const priceFor3dSecure = $(`[${subscriptionPriceAttr}]`).attr(`${subscriptionPriceAttr}`);
        let priceFor3DSecureStr = (priceFor3dSecure / 100).toFixed(2);
        if (priceFor3DSecureStr <= 0) priceFor3DSecureStr = 0.01;
        const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

        instance.requestPaymentMethod({
          threeDSecure: threeDSecureConf
        }, function (requestPaymentMethodErr, payload) {
          if (requestPaymentMethodErr) {
            toastr.warning('Something went wrong!','',{timeOut: 5000});
            toggleButtonLoading($updatePaymentMethod, false);
            return;
          }

          grecaptcha.ready(() => {
            grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.CHANGE_PAYMENT_METHOD})
              .then((token) => {
                axios.post(ENDPOINT.PAYMENT_METHOD,{
                  payment_method_nonce: payload.nonce,
                  captcha_response_token: token,
                  captcha_action: RECAPTCHA_ACTIONS.CHANGE_PAYMENT_METHOD
                })
                  .then(() => {
                    toggleButtonLoading($updatePaymentMethod, false);
                    /* eslint-disable max-len */
                    toastr.success('Your payment method has been changed successfully!','',{timeOut: 5000});
                    /* eslint-enable max-len */
                  })
                  .catch((error) => {
                    instance.clearSelectedPaymentMethod();
                    toggleButtonLoading($updatePaymentMethod, false);
                    showError(error, $updatePaymentMethod);
                  });
              });
            });
        });
      });
    });
  };

  const openTabOnload = () => {
    const id = window.location.hash ? window.location.hash.substring(1) : '';
    const $tabBtn = $(`[${itemIdAttr}="${id}"]`);
    $tabBtn.click();
  };

  $(document)
    // email input focus
    .on('click', cancelSubscriptionBtn, showCancelSubscriptionModal)
    .on('click', cancelConfirmBtn, () => {
      $cancelFlowStepOneBlock.hide();
      $cancelFlowStepTwoBlock.show();
    })
    // email input focus
    .on('click', tabBtn, (e) => {
      const $tabBtn = $(e.target);
      const btnId = $tabBtn.attr(itemIdAttr);

      if (btnId === PAYMENT_METHOD_TAB_ID) {
        $.getScript(DROPIN_SCRIPT_URL).done(handleDropinToken(braintreeDropinInit));
      }

      const $tabBtns = $(tabBtn);
      $tabBtns.removeClass(activeClassName);

      const $contentItems = $(tabContent);
      $contentItems.removeClass(activeClassName);

      const $tabContent = $(`[${itemIdAttr}="${btnId}"]`);
      $tabBtn.addClass(activeClassName);
      $tabContent.addClass(activeClassName);

      // TODO add open tab by hash from URL
    })
    .on('click',cancelDeclineBtn, hideCancelSubscriptionModal)
    .on('click', submitReason, () => {
      if (!isFormValid($cancelSubscriptionForm)) return;

      const isReasonSelected = !!$cancelSubscriptionForm.find(selectedReason).length;
      if (!isReasonSelected) {
        $cancelMessage.show();
        return;
      }

      hideError();
      toggleButtonLoading($submitReason, true);

      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.CANCEL_SUBSCRIPTION})
          .then((token) => {
            axios
              .delete(ENDPOINT.CANCEL_SUBSCRIPTION, {
                data: {
                  reason: $(selectedReason).val(),
                  comment: $textArea.val(),
                  captcha_response_token: token,
                  captcha_action: RECAPTCHA_ACTIONS.CANCEL_SUBSCRIPTION
                  }
                }
              )
              .then(() => {
                window.location.reload();
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => toggleButtonLoading($submitReason, false));
          });
      });
    });

  openTabOnload();
});

