import axios from 'axios';

import {
  ENDPOINT,
  RECAPTCHA_ACTIONS
} from '../config';
import {
  hidePromoModal,
  showEmailSentErrorModal,
  showEmailSentSuccessModal
} from './modals';
import {getRecaptchaSiteKey} from '../utils';


$(document).ready(() => {
  const emailForm = '[data-selector="email-form"]';
  const $emailForm = $(emailForm);
  const emailFormBtn = '[data-selector="email-form-btn"]';
  if (!$emailForm.length) return;

  const emailFormInput = '[data-selector="email-form-input"]';

  $(document)
    // email input focus
    .on('focus', emailFormInput, () => {
      clearTimeout(window.promoTimeout); // do not show promo modal
    })
    // click email form btn
    .on('click', emailFormBtn, (e) => {
      e.preventDefault();
      const $target = $(e.target);
      const $emailForm =  $target.closest(emailForm);
      const isPromoModalForm =  !!$target.closest('[data-selector="promo-modal"]').length;
      const $emailFormInput = $emailForm.find(emailFormInput);

      const emailFormParsley =  $target.closest(emailForm).parsley();
      emailFormParsley.validate();
      if (!emailFormParsley.isValid()) return;

      const siteKey = getRecaptchaSiteKey();
      if (!siteKey) {
        showEmailSentErrorModal();
        throw new Error('No reCaptcha site key added to HTML');
      }

      grecaptcha.ready(() => {
        grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.PRE_REG_LEAVE_EMAIL})
          .then((token) => {
            axios
              .post(ENDPOINT.WAIT_LIST, {
                email: $emailFormInput.val(),
                captcha_response_token: token,
                captcha_action: RECAPTCHA_ACTIONS.PRE_REG_LEAVE_EMAIL,
                page_url: window.location.href
              })
              .then( () => {
                $emailFormInput.val('');
                emailFormParsley.reset();
                showEmailSentSuccessModal();

                if (isPromoModalForm) hidePromoModal();
              })
              .catch(() => {
                showEmailSentErrorModal();
              });
          });
      });
    });
});

