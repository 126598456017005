import axios from 'axios';
import Cookies from 'js-cookie';
import {showPromoModal} from './components/modals';
import toastr from 'toastr';
import {getBrowser} from './utils';


toastr.options = {
  'closeButton': true,
  'debug': false,
  'newestOnTop': true,
  'progressBar': true,
  'positionClass': 'toast-top-right',
  'preventDuplicates': false,
  'onclick': null,
  'showDuration': '300',
  'hideDuration': '10000',
  'timeOut': '5000',
  'extendedTimeOut': '7000',
  'showEasing': 'swing',
  'hideEasing': 'linear',
  'showMethod': 'fadeIn',
  'hideMethod': 'fadeOut'
};

export const setCsrfTokenToRequests = () => {
  const csrfToken = Cookies.get('csrftoken');
  axios.defaults.headers.common['X-CSRFToken'] = csrfToken;
};
setCsrfTokenToRequests();

/**
 *  show promo modal on page load after delay
 */
const initPromoModal = () => {
  const delayBeforeShowPromoModal = 15000;
  window.promoTimeout = setTimeout(() => {
    showPromoModal();
  }, delayBeforeShowPromoModal);
};

if (window.isPromo) {
  initPromoModal();
}

const isSafariBrowser = getBrowser().toLowerCase().includes('safari');
if (isSafariBrowser) $('body').addClass('_safari');


$(document).ready(() => {
  const SCROLL_DELTA_DEFAULT = 40; // value for scrolling by scroll bar
  let SCROLL_WHEEL_DELTA = SCROLL_DELTA_DEFAULT;
  const SCROLL_BAR_DELTA = 5;
  let IS_PAGE_SCROLLED_DOWN = null;
  let lastScrollTop = 0;

  const updateCardsCarouselScroll = () => {
    const $homeCards = $('[data-selector="home-cards"]');

    if (!$homeCards.length) return;

    const $homeCardsTop = $('[data-selector="home-cards-top"]');
    const $homeCardsBottom = $('[data-selector="home-cards-bottom"]');

    /** coef - is describes "speed" for "home-cards" will be scrolled horizontal
     on vertical page scroll by mouse wheel **/
    const coef = 0.15;

    const isCardsBeenScrolledOff = window.scrollY > ($homeCards.offset().top + $homeCards.height());
    if (isCardsBeenScrolledOff) return;

    // set is page scrolled down or up
    const nowScrollTop = $(window).scrollTop();
    if (Math.abs(lastScrollTop - nowScrollTop) >= SCROLL_BAR_DELTA) {
      IS_PAGE_SCROLLED_DOWN = nowScrollTop > lastScrollTop;
      lastScrollTop = nowScrollTop;
    }

    // scroll by scroll bar
    if (SCROLL_WHEEL_DELTA !== SCROLL_DELTA_DEFAULT) {
      let scrollValue = SCROLL_DELTA_DEFAULT * coef;
      if (!IS_PAGE_SCROLLED_DOWN) scrollValue = scrollValue * (-1);
      $homeCardsTop[0].scrollLeft = $homeCardsTop[0].scrollLeft + scrollValue;
      $homeCardsBottom[0].scrollLeft = $homeCardsBottom[0].scrollLeft + scrollValue;
      return;
    }

    // scroll by mouse wheel
    $homeCardsTop[0].scrollLeft = $homeCardsTop[0].scrollLeft + SCROLL_WHEEL_DELTA * coef;
    $homeCardsBottom[0].scrollLeft = $homeCardsBottom[0].scrollLeft + SCROLL_WHEEL_DELTA * coef;
  };

  $(window)
    .scroll(() => {
      updateCardsCarouselScroll();
    })
    .on('wheel', (e) => {
      if (SCROLL_WHEEL_DELTA !== e.originalEvent.deltaY) {
        SCROLL_WHEEL_DELTA = e.originalEvent.deltaY;
      }
    });
});
