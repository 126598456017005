import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';


const initSentry = () => {
  if (window.isDebug) return;

  Sentry.init({
    dsn: 'https://6fedcbc3d3964b25ad243309e172fd10@o1060747.ingest.sentry.io/6055915',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

initSentry();

