import axios from 'axios';
import {
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  RECAPTCHA_ACTIONS,
  THREE_D_SECURE_ABORT_MESSAGE,
  SUBSCRIPTION_PERIOD_MAPPING,
  URLS,
} from '../../config';
import {
  currencyPipe
} from './subscription.utils';
import {
  braintreeDropinConfig,
  capitalize,
  getErrorMessage,
  getRecaptchaSiteKey,
  isThreeDSecureCanceled,
  threeDSecureConfig,
  toggleButtonLoading
} from '../../utils';
import {
  showEmailSentErrorModal,
  showPromoPlanModal
} from '../modals';
import {
  handleDropinToken
} from './subscription.utils';
import {setCsrfTokenToRequests} from '../../default';


$(document).ready(() => {
  const $subscriptionPromoComponent = $('[data-selector="subscription-promo-component"]');
  if (!$subscriptionPromoComponent.length) return;

  const braintreeDropin = '#subscription-promo-dropin-container';
  const planOffer = '[data-selector="subscription-plan-offer"]';
  const promoBtn = '[data-selector="promo-go-to-payment"]';
  const purchasePromoBtn = '[data-selector="purchase-promo-btn"]';
  const $purchasePromoBtn = $(purchasePromoBtn);

  // plan attributes
  const planAttr = {
    id: 'data-id',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    offerPeriodDisplay: 'data-offer-period-display',
    isDiscountApplicable: 'data-is-discount',
  };

  let selectedOffer = null;

  const preparePurchaseStep = () => {
    const isTrialEnabled = isTrialActive();
    const {planId, planSlug, planIsDiscountApplicable: isDiscount} = selectedOffer;
    renderPaymentDetails(isDiscount, isTrialEnabled);
    const {totalPriceInCents, priceFor3DSecure} = getTotalPrices(isTrialEnabled);

    $selectSubscriptionStep.hide();
    $purchaseSubscriptionStep.show();
    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planId, planSlug, isDiscount);
  };

  const dropinTokenCallback = () => {
    preparePurchaseStep();
    showPromoPlanModal();
    toggleButtonLoading($(promoBtn), false);
  };

  const getSelectedOfferParams = ($selectedOffer) => {
    const {
      id,
      slug,
      trialDuration,
      offerPeriod,
      price,
      offerPeriodDisplay,
      isDiscountApplicable
    } = planAttr;
    const planId = parseFloat($selectedOffer.attr(id));
    const planPeriod = $selectedOffer.attr(offerPeriod);
    const planFullPrice = parseFloat($selectedOffer.attr(price));
    const planFormattedFullPrice = (planFullPrice / 100).toFixed(2);
    const planSlug = $selectedOffer.attr(slug);
    const planPeriodDisplay = $selectedOffer.attr(offerPeriodDisplay) || planPeriod;
    const planTrialDuration = parseFloat($selectedOffer.attr(trialDuration));
    const planIsDiscountApplicable = !!$selectedOffer.attr(isDiscountApplicable);

    return {
      planId,
      planPeriod,
      planFullPrice,
      planFormattedFullPrice,
      planSlug,
      planPeriodDisplay,
      planTrialDuration,
      planIsDiscountApplicable,
    };
  };

  // select step (1st)
  const $selectSubscriptionStep = $('[data-selector="subscription-select-step"]');

  // purchase step (2nd)
  const $purchaseSubscriptionStep = $('[data-selector="subscription-purchase-step"]');
  const $purchaseSubscriptionError = $('[data-selector="subscription-error"]');

  // subscription details elements
  const $planNoTrialBlock = $('[data-selector="plan-no-trial"]');
  const $planTrialBlock = $('[data-selector="plan-trial"]');
  const $planPeriod = $('[data-selector="plan-period-value"]');
  const $planFullPrice = $('[data-selector="plan-full-price-value"]');
  const $planTotalPrice = $('[data-selector="plan-total-price-value"]');
  const $freeTrialPeriod = $('[data-selector="free-trial-period"]');
  const $planTrialDuration = $('[data-selector="trial-duration"]');
  const $planRenewConditionBlock = $('[data-selector="plan-renew-condition-block"]');
  const $planRenewCondition = $('[data-selector="plan-renew-condition"]');

  const isTrialActive = () => {
    const { planTrialDuration } = selectedOffer;
    if (!window.ableToGetTrial) return false;
    const isTrialDisabled = planTrialDuration === 0;
    return !isTrialDisabled;
  };

  const getTotalPrices = (isTrialEnabled) => {
    const {planFullPrice: planFullPriceInCents} = selectedOffer;
    const mainSubscriptionPriceInCents = parseFloat(planFullPriceInCents);
    const totalPriceInCents = isTrialEnabled ? 0 : mainSubscriptionPriceInCents;

    return { totalPriceInCents, priceFor3DSecure: mainSubscriptionPriceInCents };
  };

  const showFreeTrial = (trial) => {
    $freeTrialPeriod.show();
    if (trial === 1) {
      $planTrialDuration.text(trial + ' day');
    } else if (trial > 1) {
      $planTrialDuration.text(trial + ' days');
    } else $freeTrialPeriod.hide();
  };

  const renderPaymentDetails = (isDiscount, isTrialEnabled) => {
    const {
      planFullPrice,
      planFormattedFullPrice,
      planTrialDuration,
      planPeriodDisplay
    } = selectedOffer;
    let text = '';
    $planPeriod.text(capitalize(planPeriodDisplay));
    $planFullPrice.text(currencyPipe(planFormattedFullPrice));

    $planRenewCondition.html(text);

    if (isTrialEnabled) {
      $planTrialBlock.show();
      showFreeTrial(planTrialDuration);
      $planTotalPrice.text(currencyPipe('0.00'));

      text = SUBSCRIPTION_PERIOD_MAPPING[planPeriodDisplay](planFullPrice/100);
      $planRenewCondition.html(text);
    } else {
      $planNoTrialBlock.show();
      $planTotalPrice.text(currencyPipe(planFormattedFullPrice));
      text = 'Cancel anytime';
      $planRenewCondition.html(text);
    }

    $planRenewConditionBlock.show();
  };

  /* eslint-disable max-len */
  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, planId) => {
  /* eslint-enable */
    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showEmailSentErrorModal();
      throw new Error('No reCaptcha site key added to HTML');
    }

    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $purchasePromoBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $purchasePromoBtn.show();
            $purchaseSubscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $purchasePromoBtn.hide();
            $purchaseSubscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $purchaseSubscriptionError.hide();
            if (instance.isPaymentMethodRequestable()) {
              $purchasePromoBtn.show();
              return;
            }
            $purchasePromoBtn.hide();
          });

          toggleButtonLoading($purchasePromoBtn, false);

          $purchasePromoBtn.click(() => {
            $purchaseSubscriptionError.hide();
            toggleButtonLoading($purchasePromoBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                $purchaseSubscriptionError.text(requestPaymentMethodErr.message);
                $purchaseSubscriptionError.show();
                $purchaseSubscriptionError.show();
                toggleButtonLoading($purchasePromoBtn, false);
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $purchaseSubscriptionError.text(THREE_D_SECURE_ABORT_MESSAGE);
                $purchaseSubscriptionError.show();
                toggleButtonLoading($purchasePromoBtn, false);
                return;
              }

              // Submit payload.nonce to your server
              grecaptcha.ready(() => {
                grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.BUY_SUBSCRIPTION})
                  .then(token => {
                    /* eslint-disable max-len */
                    const requestData = getPurchaseRequestData(token, payload.nonce, planId);
                    /* eslint-enable */
                    axios
                      .post(ENDPOINT.BUY_SUBSCRIPTION, requestData)
                      .then(() => {
                        window.location = `${URLS.SIGN_UP}?next=${URLS.SUBSCRIPTION_SUCCESS}`;
                      })
                      .catch(err => {
                        instance.clearSelectedPaymentMethod();
                        $purchaseSubscriptionError.text('');
                        $purchaseSubscriptionError.append(getErrorMessage(err));
                        $purchaseSubscriptionError.show();
                      })
                      .finally(() => toggleButtonLoading($purchasePromoBtn, false));
                  });
              });
            });
          });
        });
    }
  };

  const getPurchaseRequestData = (token, paymentMethodNonce, planId, isDiscount) => {
    return {
      payment_method_nonce: paymentMethodNonce,
      plan_id: planId,
      discount_code: isDiscount ? activeCoupon : null,
      first_billing_date: null,
      captcha_response_token: token,
      captcha_action: RECAPTCHA_ACTIONS.BUY_SUBSCRIPTION
    };
  };

  $(document)
    .on('click', promoBtn, (e) => {
      e.preventDefault();
      const $clickedBtn = $(e.target);
      selectedOffer = getSelectedOfferParams($(planOffer));
      toggleButtonLoading($clickedBtn, true);

      if (window.isLoggedIn) {
        $.getScript(DROPIN_SCRIPT_URL).done(handleDropinToken(dropinTokenCallback));
        return;
      }

      axios
        .post(ENDPOINT.USER_PROFILE, {
          email: null,
          source: 'website',
          need_to_login: true,
        })
        .then(() => {
          setCsrfTokenToRequests();

          window.isLoggedIn = true;

          $.getScript(DROPIN_SCRIPT_URL).done(() => {
            handleDropinToken(dropinTokenCallback);
          });
        })
        .catch((error) => {
          /* eslint-disable */
          console.log(error);
          /* eslint-enable */
        });
    });
});
