(function () {
  const _analytics = {
    identify: function (user, callback) {
      if (window.kochava) {
        const data = {
          id: String(user.id)
        };
        if (user.email) {
          data.email = user.email;
        }
        if (user.first_name || user.last_name) {
          let name = '';
          if (user.first_name) {
            name += user.first_name;
          }
          if (user.last_name) {
            name += ' ' + user.last_name;
          }
          name = name.trim();
          data.name = name;
        }

        window.kochava.identify(data, callback);
      } else {
        callback && callback();
      }
    },

    activity: function (name, data, callback) {
      if (window.kochava) {
        window.kochava.activity(name, data, callback);
      } else {
        callback && callback();
      }
    },

    fbq: function (name, data) {
      if (window.fbq) {
        window.fbq('track', name, data);
      }
    },

    landingPage: function () {
      _analytics.fbq('ViewContent');
    },

    tutorialComplete: function (user, callback) {
      _analytics.fbq('AddToCart');
      _analytics.identify(user, function () {
        _analytics.activity('Tutorial Complete', {
          user_id: user.id
        }, callback);
      });
    },

    addToCart: function (user, subscriptionId, price, callback) {
      _analytics.identify(user, function () {
        _analytics.activity('Add To Cart', {
          subscription_id: subscriptionId,
          price: price
        }, callback);
      });
    },

    addToWishList: function (user, subscriptionId, price, callback) {
      _analytics.identify(user, function () {
        _analytics.activity('Add to Wishlist', {
          subscription_id: subscriptionId,
          price: price
        }, callback);
      });
    },

    checkoutStart: function (user, subscriptionId, price, hasTrial, callback) {
      if (hasTrial) {
        _analytics.fbq('InitiateCheckout');
        _analytics.fbq('Purchase', {value: 0.01 ,currency: 'USD'});
      } else {
        _analytics.fbq('Purchase', {value: price ,currency: 'USD'});
      }

      _analytics.identify(user, function () {
        _analytics.activity(hasTrial ? 'Checkout Start': 'Purchase', {
          subscription_id: subscriptionId,
          price: price
        }, callback);
      });
    }
  };

  window._analytics = _analytics;
})();
