import axios from 'axios';
import {
  ENDPOINT,
  GET_PARAMS,
  RECAPTCHA_ACTIONS,
  URLS
} from '../config';
import {
  showError,
  hideError,
  getRecaptchaSiteKey,
  isFormValid,
  toggleButtonLoading
} from '../utils';


$(document).ready(() => {
  const registrationForm = '[data-selector="registration-form"]';
  const $registrationForm = $(registrationForm);
  if (!$registrationForm.length) return;

  const emailInput = '[data-selector="registration-email-input"]';
  const $emailInput = $(emailInput);
  const passwordInput = '[data-selector="registration-password-input"]';
  const $passwordInput = $(passwordInput);
  const passwordConfirmInput = '[data-selector="registration-password-confirm-input"]';
  const $passwordConfirmInput = $(passwordConfirmInput);
  const regSubmitBtn = '[data-selector="registration-submit"]';
  const $regSubmitBtn = $(regSubmitBtn);

  const submitRegistrationForm = () => {
    if (!isFormValid($registrationForm)) return;

    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showError(null, $regSubmitBtn);
      throw new Error('No reCaptcha site key added to HTML');
    }

    hideError();
    toggleButtonLoading($regSubmitBtn, true);

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.REGISTRATION})
      .then((token) => {
        const searchParams = new URLSearchParams(window.location.search);
        const method = searchParams.has(GET_PARAMS.NEXT) ? 'put' : 'post';

        axios({
          method: method,
          url: ENDPOINT.USER_PROFILE,
          data: {
            email: $emailInput.val(),
            password: $passwordInput.val(),
            source: 'website',
            captcha_response_token: token,
            captcha_action: RECAPTCHA_ACTIONS.REGISTRATION
          }
          })
          .then(() => {
            if (window.location.href.includes('?') > -1) {
              const searchParams = new URLSearchParams(window.location.search);
              if (searchParams.has(GET_PARAMS.NEXT)) {
                window.location.href = searchParams.get(GET_PARAMS.NEXT);
                return;
              }
            }

            window.location.href = window.location.origin + URLS.SUBSCRIPTION_PLANS;
          })
          .catch((error) => {
            showError(error, $regSubmitBtn);
            toggleButtonLoading($regSubmitBtn, false);
          });
      });
    });
  };

  const triggerPasswordValidate = () => {
    const password = $passwordInput.val();
    const passwordConfirm = $passwordConfirmInput.val();
    if (password && passwordConfirm) {
      const regFormParsley =  $registrationForm.parsley();
      regFormParsley.validate();
    }
  };

  $(document)
    .on('click', regSubmitBtn, (e) => {
      e.preventDefault();
      submitRegistrationForm();
    })
    .on('change', `${passwordInput}, ${passwordConfirmInput}`, triggerPasswordValidate);
});
