import axios from 'axios';
import { CURRENCY, ENDPOINT } from '../../config';
import toastr from 'toastr';

export const currencyPipe = (price) => `${CURRENCY}${price}`;

export const handleDropinToken = (callback) => {
  if (window.braintree_token) {
    callback();
    return;
  }

  axios
    .post(ENDPOINT.GET_BRAINTREE_TOKEN)
    .then((response) => {
      const { data } = response;
      window.braintree_token = data.token;
      callback();
    })
    .catch((error) => {
      /* eslint-disable */
      console.log(error);
      /* eslint-enable */
      toastr.warning('Something went wrong. Please reload the page','',{timeOut: 5000});
    });
};
