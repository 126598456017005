import {SUPPORT_EMAIL} from './config';
import toastr from 'toastr';


export const hideError = () => {
  $('[data-selector="global-error-message"]').fadeOut();
};

const getDefaultErrorMsg = () => {
  return `Something went wrong. Please <a href="mailto:${SUPPORT_EMAIL}}">contact us.</a> `;
};

/**
 * Returns default error msg if no params added
 *
 * @param response
 * @param $btn
 * @param addLinkToEmail
 */
export const showError = (response, $btn, addLinkToEmail = true) => {
  const errorMsgSelector = 'data-selector="global-error-message"';
  const errorMsg = getErrorMessage(response, addLinkToEmail);
  const html = `<div class="global-error-message" ${errorMsgSelector}>
                  ${errorMsg}
                </div>`;

  if (!$btn || !$btn.length) {
    toastr.warning(errorMsg);
    return;
  }

  // remove already added elem
  const $errorElem = $btn.closest('form').find(`[${errorMsgSelector}]`);
  $errorElem.remove();

  $btn.before(html);
};

export const getRecaptchaSiteKey = () => {
  const dataAttr = 'data-recaptcha-site-key';
  const $elem = $(`[${dataAttr}]`);
  if (!$elem.length) return null;

  const siteKey = $elem.attr(dataAttr);
  if (!siteKey.length) return null;

  return siteKey;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getErrorMessage = (response, addLinkToEmail) => {
  let errorMsg = '';

  const defaultErrorMsg = getDefaultErrorMsg();
  if (!response) return defaultErrorMsg;
  if (!response.response) return defaultErrorMsg;
  if (!response.response.data) return defaultErrorMsg;
  if (!response.response.data.detail && !response.response.data.error) return defaultErrorMsg;

  const keysArr = response.response.data.detail ? Object.keys(response.response.data.detail) : [];
  if (!keysArr.length) {
    if (response.response.data.error) {
      errorMsg += response.response.data.error;
      const updatedErrorText =  replaceEmailWithLink(errorMsg, addLinkToEmail);
      return updatedErrorText;
    }

    return defaultErrorMsg;
  }

  keysArr.forEach((key) => {
    if (!response.response.data.detail[key][0]) return defaultErrorMsg;
    if (!response.response.data.detail[key][0].message) return defaultErrorMsg;
    /* eslint-disable max-len */
    const capitalizedErrorMsg = capitalizeFirstLetter(`${response.response.data.detail[key][0].message} `);
    /* eslint-enable */
    errorMsg += capitalizedErrorMsg;
  });

  if (!errorMsg) return defaultErrorMsg;

  const updatedErrorText =  replaceEmailWithLink(errorMsg, addLinkToEmail);
  return updatedErrorText;
};

export const replaceEmailWithLink = (error, addLinkToEmail=true) => {
  const emailLinkHref = `mailto:${SUPPORT_EMAIL}`;
  const text1 = 'contact us';
  const text2 = 'contact support';

  if (error.indexOf('@') > -1 && addLinkToEmail) {
    const email = error.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    return error.replace(email[0], `<a href="mailto:${email[0]}">${email[0]}</a>`);
  }

  if (error.toLowerCase().indexOf(text1) > -1 && addLinkToEmail) {
    return error.replace(text1, `<a href="${emailLinkHref}">${text1}</a>`);
  }

  if (error.toLowerCase().indexOf(text2) > -1 && addLinkToEmail) {
    return error.replace(text2, `<a href="${emailLinkHref}">${text2}</a>`);
  }

  return error;
};

export const getUrlParam = (paramName) => {
  if (typeof URLSearchParams === 'function') {
    const paramValue = new URLSearchParams(window.location.search).get(paramName);
    return paramValue;
  }

  // fallback for IE
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) return null;
  return decodeURI(results[1]) || null;
};

export const isFormValid = ($form) => {
  const formParsley = $form.parsley();
  formParsley.validate();

  return formParsley.isValid();
};

export const toggleButtonLoading = ($button, isLoading, callback = () => {}) => {
  if (!$button) return;
  if (!$button.length) return;

  if (isLoading) {
    $button.each((i, button) => {
      const $button = $(button);
      $button.attr('data-value', $button.html());
      $button.attr('disabled', true);
      const buttonWidth = $button.width();
      $button.width(buttonWidth);
      $button.html('<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>');
    });
  } else {
    $button.each((i, button) => {
      const $button = $(button);
      $button.html($button.attr('data-value'));
      $button.attr('disabled', false);
      $button.css('width', '');
    });
  }

  callback();
};

export const capitalize = (string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const braintreeDropinConfig = (totalAmount, container = '#dropin-container') => {
  const config = {
    authorization: window.braintree_token,
    container: container,
    threeDSecure: true,
    vaultManager: true,
    card: {
      cardholderName: {
        required: true
      },
      overrides: {
        styles: {
          '.invalid': {
            color: 'red'
          }
        }
      }
    },
    paypal: {
      flow: 'vault',
      buttonStyle: {
        size: 'medium'
      }
    },
    paypalCredit: {
      flow: 'vault',
      buttonStyle: {
        size: 'medium'
      }
    },
    venmo: {},
    applePay: {
      displayName: 'Learn Like Nastya',
      paymentRequest: {
        total: {
          label: 'Learn Like Nastya',
          amount: totalAmount // should be in dollars (string): '152.99', '0.99'
        },
        // We recommend collecting billing address information, at minimum
        // billing postal code, and passing that billing postal code with all
        // Google Pay transactions as a best practice.
        requiredBillingContactFields: []
      },
    },
  };

  if (window.ENV !== 'prod') {
    config.googlePay = {
      googlePayVersion: 2,
      merchantId: '09638095500891200480',
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: totalAmount, // should be in dollars (string): '152.99', '0.99'
        currencyCode: 'USD'
      },
      allowedPaymentMethods: [{
        type: 'CARD',
        parameters: {
          // We recommend collecting and passing billing address information
          // with all Google Pay transactions as a best practice.
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'FULL'
          }
        }
      }]
    };
  }

  return config;
};

export const isASCII = (str) => {
  return /^[\x00-\x7F]*$/.test(str);
};

export const threeDSecureConfig = (totalPrice) => {
  const givenName = window.user && window.user.first_name;
  const surname = window.user && window.user.last_name;
  return {
    amount: parseFloat(totalPrice),
    email: window.user && window.user.email,
    billingAddress: {
      // ASCII-printable characters required, else will throw a validation error
      givenName: isASCII(givenName) && givenName,
      // ASCII-printable characters required, else will throw a validation error
      surname: isASCII(surname) && surname
    }
  };
};

export const isThreeDSecureCanceled = (payload) => {
  const { type, liabilityShifted: isThreeDSecurePassed } = payload;
  return type === 'CreditCard' && !isThreeDSecurePassed;
};

export const isElementIsVisibleOnScreen = ($elem, offset) => {
  if (!$elem.length) return;

  const topOfElement = $elem.offset().top;
  const bottomOfElement = $elem.offset().top + $elem.outerHeight();
  const bottomOfScreen = $(window).scrollTop() + $(window).innerHeight();
  const topOfScreen = $(window).scrollTop();

  if ((bottomOfScreen > (topOfElement)) && (topOfScreen < (bottomOfElement -  offset))) return true;
  return false;
};

export const isMobile = () => {
  const maxMobileWidth = 767;
  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  if (viewportWidth <= maxMobileWidth) return true;
  return false;
};

export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let tem;
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return 'IE ' + (tem[1] || '');
  }

  if (M[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = userAgent.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};
