import axios from 'axios';

import {
  URLS,
  ENDPOINT,
  GET_PARAMS,
  RECAPTCHA_ACTIONS
} from '../config';
import {
  getRecaptchaSiteKey,
  showError,
  hideError,
  isFormValid,
  toggleButtonLoading
} from '../utils';


$(document).ready(() => {
  const loginForm = '[data-selector="login-form"]';
  const $loginForm = $(loginForm);
  if (!$loginForm.length) return;

  const loginEmailInput = '[data-selector="login-email-input"]';
  const $loginEmailInput = $(loginEmailInput);
  const loginPasswordInput = '[data-selector="login-password-input"]';
  const $loginPasswordInput = $(loginPasswordInput);
  const loginSubmitBtn = '[data-selector="login-submit"]';
  const $loginSubmitBtn = $(loginSubmitBtn);

  const submitLoginForm = () => {
    if (!isFormValid($loginForm)) return;

    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showError(null, $loginSubmitBtn);
      throw new Error('No reCaptcha site key added to HTML');
    }

    hideError();
    toggleButtonLoading($loginSubmitBtn, true);

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.LOGIN})
      .then((token) => {
        axios
          .post(ENDPOINT.USER_LOGIN, {
            email: $loginEmailInput.val(),
            password: $loginPasswordInput.val(),
            captcha_response_token: token,
            captcha_action: RECAPTCHA_ACTIONS.LOGIN
          })
          .then(() => {
            if (window.location.href.includes('?') > -1) {
              const searchParams = new URLSearchParams(window.location.search);
              if (searchParams.has(GET_PARAMS.NEXT)) {
                window.location.href = searchParams.get(GET_PARAMS.NEXT);
                return;
              }
            }

            window.location.href = URLS.SUBSCRIPTION_PLANS;
          })
          .catch((error) => {
            showError(error, $loginSubmitBtn);
            toggleButtonLoading($loginSubmitBtn, false);
          });
      });
    });
  };

  $(document)
    .on('click', loginSubmitBtn, (e) => {
      e.preventDefault();
      submitLoginForm();
    });
});
