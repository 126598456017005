$(function () {
  window.Parsley.addValidator('password', {
    requirementType: 'string',
    validateString: function (value) {
      const regex = new RegExp('(?=.*[a-zA-Z])(\w*)(?=.{6,})');
      return regex.test(value);
    },
    messages: {
      en: 'Password should be at least 6 characters long and have 1 letter'
    }
  });
});
