const modal = '[data-modal]';
const promoModal = '[data-selector="promo-modal"]';
const promoPlanModal = '[data-selector="promo-plan-modal"]';
const modalOverlay = '[data-selector="modal-overlay"]';
const emailSentErrorModal = '[data-selector="email-sent-error-modal"]';
const emailSentSuccessModal = '[data-selector="email-sent-success-modal"]';
const cancelSubscriptionModal = '[data-selector="cancel-subscription-modal"]';
const close = '[data-selector="modal-close"]';


export const showEmailSentErrorModal = () => {
  $(emailSentErrorModal).fadeIn();
};

export const showEmailSentSuccessModal = () => {
  $(emailSentSuccessModal).fadeIn();
};

export const showPromoPlanModal = () => {
  $(promoPlanModal).fadeIn();
};

export const hidePromoPlanModal = () => {
  $(promoPlanModal).fadeOut();
};

export const showPromoModal = () => {
  $(promoModal).fadeIn();
};

export const hidePromoModal = () => {
  $(promoModal).fadeOut();
};

export const showCancelSubscriptionModal = () => {
  $(cancelSubscriptionModal).fadeIn();
};

export const hideCancelSubscriptionModal = () => {
  $(cancelSubscriptionModal).fadeOut();
};

/* eslint-disable */
$(document).ready(() => {
  $(document)
    .on('click', close, (e) => {
      const $modal = $(e.target).closest(modal);
      $modal.fadeOut();
    })
    .on('click', modalOverlay, (e) => {
      e.stopPropagation();
      const $modal = $(e.target).closest(modal);
      $modal.fadeOut();
    })
    // close all modal on Ecs click
    .keydown((e) => {
      if (e.key === 'Escape') $(modal).fadeOut();
    });
});

/* eslint-enable */
