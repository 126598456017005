import 'parsleyjs/dist/parsley';
import lazySizes from 'lazysizes';
lazySizes.init();

import toastr from 'toastr';
window.toastr = toastr;

import './default';
import './components/kochava-wrapper';
import './components/parsley-validators';
import './components/email-form';
import './components/sentry';
import './components/registration';
import './components/login';
import './components/password-reset';
import './components/account-settings';
import './components/payment/subscription-plans';
import './components/payment/subscription-promo';
import './components/plans-timer';
