/* eslint-disable max-len */
export const ENDPOINT = {
  USER_PROFILE:        '/api/v1/user/',
  USER_LOGIN:          '/api/v1/auth/',
  WAIT_LIST:           '/api/v1/waitlist/',
  USER_PASSWORD_RESET: '/api/v1/user/password/',
  GET_BRAINTREE_TOKEN: '/api/v1/subscription/braintree/token/',
  COUPON_VALIDATE:     '/api/v1/subscription/braintree/discount/',
  BUY_SUBSCRIPTION:    '/api/v1/subscription/braintree/',
  CANCEL_SUBSCRIPTION: '/api/v1/subscription/',
  PAYMENT_METHOD:      '/api/v1/payments/braintree/payment-method/',
};

export const RECAPTCHA_ACTIONS = {
  REGISTRATION:           'REGISTRATION',
  LOGIN:                  'LOGIN',
  PRE_REG_LEAVE_EMAIL:    'PRE_REG_LEAVE_EMAIL',
  PASSWORD_RESET:         'PASSWORD_RESET',
  COUPON_VALIDATE:        'COUPON_VALIDATE',
  BUY_SUBSCRIPTION:       'BUY_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION:    'CANCEL_SUBSCRIPTION',
  CHANGE_PAYMENT_METHOD:  'CHANGE_PAYMENT_METHOD',
};

export const SUPPORT_EMAIL = 'support@learnlikenastya.com';
/* eslint-enable */

export const DROPIN_SCRIPT_URL = 'https://js.braintreegateway.com/web/dropin/1.33.7/js/dropin.min.js';

export const GET_PARAMS = {
  NEXT: 'next',
  COUPON: 'coupon',
};

export const CURRENCY = '$';

export const THREE_D_SECURE_ABORT_MESSAGE = 'Purchase aborted, please select payment options';

export const URLS = {
  PASSWORD_RESET_SUCCESS: '/password-reset/done',
  SUBSCRIPTION_PLANS: '/subscription-plans/',
  SUBSCRIPTION_SUCCESS: '/subscription-success/',
  SIGN_UP: '/signup/',
};

export const SUBSCRIPTION_PERIOD_MAPPING = {
  /* eslint-disable max-len */
  '1 Day':     (price) => `<p>Renews at ${CURRENCY}${price} / day<br>Cancel anytime</p>`,
  'Half Year': (price) => `<p>Renews at ${CURRENCY}${price} / half year after free trial<br>Cancel anytime</p>`,
  'Monthly':     (price) => `<p>Renews at ${CURRENCY}${price} / month after free trial<br>Cancel anytime</p>`,
  'Quarterly':   (price) => `<p>Renews at ${CURRENCY}${price} / quarter after free trial<br>Cancel anytime</p>`,
  'Yearly':      (price) => `<p>Renews at ${CURRENCY}${price} / year after free trial<br>Cancel anytime</p>`,
  'Lifetime':    () => ''
  /* eslint-enable */
};
