import axios from 'axios';
import {
  DROPIN_SCRIPT_URL,
  ENDPOINT,
  GET_PARAMS,
  RECAPTCHA_ACTIONS,
  THREE_D_SECURE_ABORT_MESSAGE,
  SUBSCRIPTION_PERIOD_MAPPING,
  URLS
} from '../../config';
import {
  currencyPipe
} from './subscription.utils';
import {
  braintreeDropinConfig,
  capitalize,
  getErrorMessage,
  getRecaptchaSiteKey,
  getUrlParam,
  isFormValid,
  isThreeDSecureCanceled,
  threeDSecureConfig,
  toggleButtonLoading
} from '../../utils';
import { showEmailSentErrorModal } from '../modals';
import {handleDropinToken} from './subscription.utils';


$(document).ready(() => {
  const $subscriptionPlansComponent = $('[data-selector="subscription-plans-component"]');
  if (!$subscriptionPlansComponent.length) return;

  const braintreeDropin = '#subscription-plans-dropin-container';

  // plan card
  const planOffer = '[data-selector="subscription-plan-offer"]';
  const $subscriptionPlan = $('[data-selector="subscription-plan-offer"]');
  const subscriptionPrice = '[data-selector="subscription-price"]';

  /* eslint-disable max-len */
  //{ planPeriod, planFullPrice, planFormattedFullPrice, planSlug, planPeriodDisplay, planTrialDuration, planIsDiscountApplicable }
  let selectedOffer = null;
  /* eslint-enable */

  // plan attributes
  const planAttr = {
    id: 'data-id',
    slug: 'data-slug',
    trialDuration: 'data-trial-duration',
    offerPeriod: 'data-offer-period',
    price: 'data-price',
    offerPeriodDisplay: 'data-offer-period-display',
    isDiscountApplicable: 'data-is-discount',
  };

  // select step (1st)
  const $selectSubscriptionStep = $('[data-selector="subscription-select-step"]');
  const couponForm = '[data-selector="subscription-coupon-form"]';
  const $couponForm = $(couponForm);
  const $couponInput = $('[data-selector="subscription-coupon-input"]');
  const $couponApplyBtn = $('[data-selector="coupon-form-submit"]');
  const couponResetBtn = '[data-selector="coupon-form-reset"]';
  const $couponResetBtn = $(couponResetBtn);
  const $couponMessage = $('[data-selector="coupon-message"]');
  const goToPaymentStepBtn = '[data-selector="go-to-payment-btn"]';
  const $goToPaymentStepBtn = $(goToPaymentStepBtn);

  // coupon
  let activeCoupon = null;
  let amountOff = 0;
  let specialCouponTrial = null;
  let includedPlans = [];
  const couponAppliedClass = '_coupon-applied';

  // purchase step (2nd)
  const $purchaseSubscriptionStep = $('[data-selector="subscription-purchase-step"]');
  const submitPaymentBtn = '[data-selector="submit-payment-btn"]';
  const $submitPaymentBtn = $(submitPaymentBtn);
  const returnBtn = '[data-selector="back-to-prev-section-btn"]';
  const $returnBtn = $(returnBtn);
  const $purchaseSubscriptionError = $('[data-selector="subscription-error"]');

  // subscription details elements
  const $planNoTrialBlock = $('[data-selector="plan-no-trial"]');
  const $planTrialBlock = $('[data-selector="plan-trial"]');
  const $planPeriod = $('[data-selector="plan-period-value"]');
  const $planFullPrice = $('[data-selector="plan-full-price-value"]');
  const $planDiscountRow = $('[data-selector="plan-discount-row"]');
  const $planCouponName = $('[data-selector="plan-coupon-name"]');
  const $planTotalPrice = $('[data-selector="plan-total-price-value"]');
  const $freeTrialPeriod = $('[data-selector="free-trial-period"]');
  const $planTrialDuration = $('[data-selector="trial-duration"]');
  const $discountBlock = $('[data-selector="discount-block"]');
  const $discountValue = $('[data-selector="discount-value"]');
  const $planRenewConditionBlock = $('[data-selector="plan-renew-condition-block"]');
  const $planRenewCondition = $('[data-selector="plan-renew-condition"]');

  const applyCouponFromGetParams = () => {
    const coupon = getUrlParam(GET_PARAMS.COUPON);
    if (!coupon) return;

    $couponInput.val(coupon);
    $couponForm.submit();
  };

  const applyCouponToPlans = () => {
    const $plansCouponApplicableTo = $subscriptionPlan
      .filter((i, plan) => {
        const planSlug = $(plan).attr(planAttr.slug);
        return includedPlans.indexOf(planSlug) !== -1;
      });

    $plansCouponApplicableTo.each((i, plan) => {
      const $plan = $(plan);
      const price = parseFloat($plan.attr(planAttr.price));
      const priceWithDiscount = price - amountOff;
      const discountPrice = priceWithDiscount < 0 ? 0 : priceWithDiscount;
      const formattedDiscountPrice = (discountPrice / 100).toFixed(2);
      $plan.find(subscriptionPrice)
        .text(currencyPipe(formattedDiscountPrice))
        .addClass(couponAppliedClass);
      $plan.attr(planAttr.isDiscountApplicable, true);
    });
  };

  const clearCouponSettings = () => {
    activeCoupon = null;
    amountOff = 0;
    specialCouponTrial = null;
    includedPlans = [];
  };

  const resetAppliedCouponUI = () => {
    $subscriptionPlan.each((i, plan) => {
      const $plan = $(plan);
      const planFullPrice = $plan.attr(planAttr.price);
      const formattedFullPrice = (planFullPrice / 100).toFixed(2);
      const $price = $plan.find(subscriptionPrice);
      $price.text(currencyPipe(formattedFullPrice)).removeClass(couponAppliedClass);
      $plan.removeAttr(planAttr.isDiscountApplicable);
    });
  };

  const handleCouponValidate = (coupon) => {
    toggleButtonLoading($couponApplyBtn, true);

    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showEmailSentErrorModal();
      throw new Error('No reCaptcha site key added to HTML');
    }

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.COUPON_VALIDATE})
        .then(token => {
          axios
            .post(ENDPOINT.COUPON_VALIDATE, {
              code: coupon,
              captcha_response_token: token,
              captcha_action: RECAPTCHA_ACTIONS.COUPON_VALIDATE
            })
            .then(response => {
              const { data } = response;
              activeCoupon = coupon;
              amountOff = data.amount_off;
              specialCouponTrial = data.trial_duration;
              includedPlans = data.included_plans;

              $couponInput.parsley().removeError('coupon');
              $couponInput.prop('disabled', true);
              $couponApplyBtn.hide();
              $couponResetBtn.show();
              $couponMessage.show();
              applyCouponToPlans();
            })
            .catch(err => {
              clearCouponSettings();
              $couponInput.parsley().removeError('coupon');
              $couponInput.parsley().addError('coupon', {
                message: err.response.data.error
              });
            })
            .finally(() => toggleButtonLoading($couponApplyBtn, false));
        });
    });
  };

  const getSelectedOfferParams = ($selectedOffer) => {
    const {
      id,
      slug,
      trialDuration,
      offerPeriod,
      price,
      offerPeriodDisplay,
      isDiscountApplicable
    } = planAttr;
    const planId = parseFloat($selectedOffer.attr(id));
    const planPeriod = $selectedOffer.attr(offerPeriod);
    const planFullPrice = parseFloat($selectedOffer.attr(price));
    const planFormattedFullPrice = (planFullPrice / 100).toFixed(2);
    const planSlug = $selectedOffer.attr(slug);
    const planPeriodDisplay = $selectedOffer.attr(offerPeriodDisplay) || planPeriod;
    const planTrialDuration = parseFloat($selectedOffer.attr(trialDuration));
    const planIsDiscountApplicable = !!$selectedOffer.attr(isDiscountApplicable);

    return {
      planId,
      planPeriod,
      planFullPrice,
      planFormattedFullPrice,
      planSlug,
      planPeriodDisplay,
      planTrialDuration,
      planIsDiscountApplicable,
    };
  };

  const isTrialActive = () => {
    // Coupon trial has the highest priority. If set to 0, the trial is cancelled.
    const { planTrialDuration, planIsDiscountApplicable } = selectedOffer;
    if (planIsDiscountApplicable) {
      const isTrialDisabledByCoupon = specialCouponTrial === 0;
      return !isTrialDisabledByCoupon;
    }

    if (!window.ableToGetTrial) return false;

    const isTrialDisabled = planTrialDuration === 0;
    return !isTrialDisabled;
  };

  const dropinTokenCallback = () => {
    toggleButtonLoading($goToPaymentStepBtn, false);
    preparePurchaseStep();
  };

  const getTotalPrices = (isTrialEnabled) => {
    const {
      planFullPrice: planFullPriceInCents,
      planIsDiscountApplicable: isDiscountApplicable
    } = selectedOffer;
    const mainSubscriptionPriceInCents = parseFloat(planFullPriceInCents);
    let totalPriceInCents = isTrialEnabled ? 0 : mainSubscriptionPriceInCents;

    if (isDiscountApplicable) {
      const amountPriceInCents = parseFloat(amountOff.toFixed());
      const priceAfterDiscount = parseFloat((totalPriceInCents - amountPriceInCents).toFixed());
      totalPriceInCents = priceAfterDiscount < 0 ? 0 : priceAfterDiscount;
    }

    const getPriceFor3ds = () => {
      /* eslint-disable max-len */
      const minCapFor3DSecureInCents = 1;
      let threeDSecurePrice = mainSubscriptionPriceInCents;

      if (isDiscountApplicable) {
        const amountPriceInCents = parseFloat(amountOff.toFixed());
        const threeDSecurePriceAfterDiscount = parseFloat((threeDSecurePrice - amountPriceInCents).toFixed());
        // fix for 3d secure, we CAN NOT use 0 as price for 3d secure
        threeDSecurePrice = threeDSecurePriceAfterDiscount <= 0 ? minCapFor3DSecureInCents : threeDSecurePriceAfterDiscount;
      }

      return threeDSecurePrice;
      /* eslint-enable max-len */
    };

    return { totalPriceInCents, priceFor3DSecure: getPriceFor3ds() };
  };

  const preparePurchaseStep = () => {
    $submitPaymentBtn.hide();
    const isTrialEnabled = isTrialActive();
    const { planId, planSlug, planIsDiscountApplicable: isDiscount } = selectedOffer;
    renderPaymentDetails(isDiscount, isTrialEnabled);
    const { totalPriceInCents, priceFor3DSecure } = getTotalPrices(isTrialEnabled);

    // TODO: are there events to be triggered?

    $selectSubscriptionStep.hide();
    $purchaseSubscriptionStep.show();
    initBraintreeDropin(totalPriceInCents, priceFor3DSecure, planId, planSlug, isDiscount);
  };

  const clearPaymentDetails = () => {
    $planTrialBlock.hide();
    $planNoTrialBlock.hide();
    $purchaseSubscriptionError.hide();
    $planPeriod.text('');
    $planCouponName.text('-');
    $planFullPrice.text('');
    $planTotalPrice.text('');
  };

  const prepareSelectStep = () => {
    $submitPaymentBtn.off('click');
    $purchaseSubscriptionStep.hide();
    $planDiscountRow.hide();
    $selectSubscriptionStep.show();
    clearPaymentDetails();
    $(braintreeDropin).html('');
    selectedOffer = null;
    $freeTrialPeriod.hide();
    $discountBlock.hide();
    $planRenewConditionBlock.hide();
  };

  const showFreeTrial = (trial) => {
    $freeTrialPeriod.show();
    if (trial === 1) {
      $planTrialDuration.text(trial + ' day');
    } else if (trial > 1) {
      $planTrialDuration.text(trial + ' days');
    } else $freeTrialPeriod.hide();
  };

  const renderPaymentDetails = (isDiscount, isTrialEnabled) => {
    const {
      planFullPrice,
      planFormattedFullPrice,
      planTrialDuration,
      planPeriodDisplay
    } = selectedOffer;
    const text = SUBSCRIPTION_PERIOD_MAPPING[planPeriodDisplay](planFullPrice/100);
    $planPeriod.text(capitalize(planPeriodDisplay));
    $planFullPrice.text(currencyPipe(planFormattedFullPrice));
    if (isDiscount) {
      const planPriceWithDiscount = planFullPrice - amountOff;
      const planDiscountPrice = planPriceWithDiscount < 0 ? 0 : planPriceWithDiscount;
      const planFormattedDiscountPrice = (planDiscountPrice / 100).toFixed(2);
      if (isTrialEnabled) {
        showFreeTrial(specialCouponTrial);
        $planTotalPrice.text(currencyPipe('0.00'));
        $planRenewConditionBlock.show();
        $planRenewCondition.html(text);
      } else $planTotalPrice.text(currencyPipe(planFormattedDiscountPrice));
      $planCouponName.text(activeCoupon);
      $planDiscountRow.show();
      $discountBlock.show();
      $discountValue.text(currencyPipe(amountOff / 100));
    } else if (isTrialEnabled) {
      showFreeTrial(planTrialDuration);
      $planTotalPrice.text(currencyPipe('0.00'));
      $planRenewConditionBlock.show();
      $planRenewCondition.html(text);
    } else $planTotalPrice.text(currencyPipe(planFormattedFullPrice));

    isTrialEnabled ? $planTrialBlock.show() : $planNoTrialBlock.show();
  };

  /* eslint-disable max-len */
  const initBraintreeDropin = (totalPriceInCents, priceFor3DSecure, planId, planSlug, isDiscount) => {
  /* eslint-enable */
    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showEmailSentErrorModal();
      throw new Error('No reCaptcha site key added to HTML');
    }

    if (braintree && $(braintreeDropin).length) {
      const totalPriceInDollars = (totalPriceInCents / 100).toFixed(2);
      const braintreeConf = braintreeDropinConfig(totalPriceInDollars, braintreeDropin);

      braintree.dropin.create(braintreeConf,
        function (createErr, instance) {
          if (createErr) {
            // An error in the create call is likely due to
            // incorrect configuration values or network issues.
            // An appropriate error will be shown in the UI.

            // eslint-disable-next-line no-console
            console.error('dropin create error: ', createErr);
            return;
          }

          if (instance.isPaymentMethodRequestable()) {
            // This will be true if you generated the client token
            // with a customer ID and there is a saved payment method
            // available to tokenize with that customer.
            $submitPaymentBtn.show();
          }

          instance.on('paymentMethodRequestable', () => {
            $submitPaymentBtn.show();
            $purchaseSubscriptionError.hide();
          });

          instance.on('noPaymentMethodRequestable', () => {
            $submitPaymentBtn.hide();
            $purchaseSubscriptionError.hide();
          });

          instance.on('paymentOptionSelected', () => {
            $purchaseSubscriptionError.hide();
            if (instance.isPaymentMethodRequestable()) {
              $submitPaymentBtn.show();
              return;
            }
            $submitPaymentBtn.hide();
          });

          toggleButtonLoading($returnBtn, false);
          toggleButtonLoading($submitPaymentBtn, false);

          $submitPaymentBtn.click(() => {
            // To prevent bot spamming we use solution from
            // https://www.thryv.com/blog/honeypot-technique/
            // const $hiddenInput = $(hiddenInputSelect);
            // const hiddenInputVal = $hiddenInput.val();
            // if (hiddenInputVal) return;

            $purchaseSubscriptionError.hide();
            $returnBtn.hide();
            toggleButtonLoading($submitPaymentBtn, true);

            const priceFor3DSecureStr = (priceFor3DSecure / 100).toFixed(2).toString();
            const threeDSecureConf = threeDSecureConfig(priceFor3DSecureStr);

            instance.requestPaymentMethod({
              threeDSecure: threeDSecureConf
            }, function (requestPaymentMethodErr, payload) {
              if (requestPaymentMethodErr) {
                // eslint-disable-next-line no-console
                console.error('requestPaymentMethodErr: ', requestPaymentMethodErr);
                $purchaseSubscriptionError.text(requestPaymentMethodErr.message);
                $purchaseSubscriptionError.show();
                $purchaseSubscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                return;
              }

              if (isThreeDSecureCanceled(payload)) {
                instance.clearSelectedPaymentMethod();
                $purchaseSubscriptionError.text(THREE_D_SECURE_ABORT_MESSAGE);
                $purchaseSubscriptionError.show();
                toggleButtonLoading($submitPaymentBtn, false);
                $returnBtn.show();
                return;
              }

              _analytics.activity('Add To Cart', {
                subscription_slug: planSlug,
                price: ((totalPriceInCents / 100).toFixed(2) - amountOff).toFixed(2)
              });

              // Submit payload.nonce to your server
              grecaptcha.ready(() => {
                grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.BUY_SUBSCRIPTION})
                  .then(token => {
                    /* eslint-disable max-len */
                    const requestData = getPurchaseRequestData(token, payload.nonce, planId, isDiscount);
                    /* eslint-enable */
                    axios
                      .post(ENDPOINT.BUY_SUBSCRIPTION, requestData)
                      .then(response => {
                        const { data } = response;
                        _analytics.checkoutStart(
                          {email: window.user.email || ''},
                          planSlug,
                          totalPriceInDollars,
                          data.is_trial,
                          () => {
                            window.location = URLS.SUBSCRIPTION_SUCCESS;
                          }
                        );
                      })
                      .catch(err => {
                        instance.clearSelectedPaymentMethod();
                        $purchaseSubscriptionError.text('');
                        $purchaseSubscriptionError.append(getErrorMessage(err));
                        $purchaseSubscriptionError.show();
                        $returnBtn.show();
                      })
                      .finally(() => toggleButtonLoading($submitPaymentBtn, false));
                  });
              });
            });
          });
        });
    }
  };

  const getPurchaseRequestData = (token, paymentMethodNonce, planId, isDiscount) => {
    return {
      payment_method_nonce: paymentMethodNonce,
      plan_id: planId,
      discount_code: isDiscount ? activeCoupon : null,
      first_billing_date: null,
      captcha_response_token: token,
      captcha_action: RECAPTCHA_ACTIONS.BUY_SUBSCRIPTION
    };
  };

  $(document)
    .on('submit', couponForm, (e) => {
      e.preventDefault();
      const coupon = $couponInput.val().trim();
      if (!coupon) return;

      if (!isFormValid($couponForm)) return;

      handleCouponValidate(coupon);
    })
    .on('click', couponResetBtn, (e) => {
      e.preventDefault();
      clearCouponSettings();
      resetAppliedCouponUI();

      $couponInput.parsley().reset();
      $couponInput.prop('disabled', false);
      $couponInput.val('');
      $couponMessage.hide();
      $couponResetBtn.hide();
      $couponApplyBtn.show();
    })
    .on('click',goToPaymentStepBtn, (e) => {
      e.preventDefault();
      const $clickedBtn = $(e.target);
      selectedOffer = getSelectedOfferParams($clickedBtn.closest(planOffer));
      toggleButtonLoading($clickedBtn, true);

      $.getScript(
        DROPIN_SCRIPT_URL,
        () => {handleDropinToken(dropinTokenCallback);}
      );
    })
    .on('click', returnBtn, prepareSelectStep);

  applyCouponFromGetParams();
});
