import {
  getRecaptchaSiteKey,
  hideError,
  isFormValid,
  showError,
  toggleButtonLoading
} from '../utils';
import {
  ENDPOINT,
  RECAPTCHA_ACTIONS,
  URLS
} from '../config';
import axios from 'axios';

$(document).ready(() => {
  const passwordResetForm = '[data-selector="password-reset-form"]';
  const $passwordResetForm = $(passwordResetForm);
  const passwordResetConfirmForm = '[data-selector="password-reset-confirm-form"]';
  const $passwordResetConfirmForm = $(passwordResetConfirmForm);

  if (!$passwordResetForm.length && !$passwordResetConfirmForm.length) return;

  const $passwordResetEmailInput = $('[data-selector="password-reset-email-input"]');
  const $passwordResetButton = $('[data-selector="password-reset-button"]');
  const passwordResetConfirmButton = '[data-selector="password-reset-confirm-button"]';


  const submitPasswordResetForm = () => {
    if (!isFormValid($passwordResetForm)) return;

    const siteKey = getRecaptchaSiteKey();
    if (!siteKey) {
      showError(null, $passwordResetButton);
      throw new Error('No reCaptcha site key added to HTML');
    }

    hideError();

    toggleButtonLoading($passwordResetButton, true);

    grecaptcha.ready(() => {
      grecaptcha.execute(siteKey, {action: RECAPTCHA_ACTIONS.PASSWORD_RESET})
      .then((token) => {
        axios
          .post(ENDPOINT.USER_PASSWORD_RESET, {
            email: $passwordResetEmailInput.val(),
            captcha_response_token: token,
            captcha_action: RECAPTCHA_ACTIONS.PASSWORD_RESET
          })
          .then(() => {
            toggleButtonLoading($passwordResetButton, false);
            window.location = URLS.PASSWORD_RESET_SUCCESS;
          })
          .catch((error) => {
            toggleButtonLoading($passwordResetButton, false);
            showError(error);
          });
      });
    });
  };

  $(document)
    .on('submit', passwordResetForm, (e) => {
      e.preventDefault();
      submitPasswordResetForm();
    })
    .on('click', passwordResetConfirmButton, isFormValid.bind(this, $passwordResetConfirmForm));
});
